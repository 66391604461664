// Nodes
export const ADD_NODE_TO_ARRAY = "ADD_NODE_TO_ARRAY";
export const SAVE_NODES_TO_FILE = "SAVE_NODES_TO_FILE";
export const DELETE_NODES_FROM_ARRAY = "DELETE_NODES_FROM_ARRAY";

// Repo files
export const FETCH_REPO_FILES = "FETCH_REPO_FILES";
export const STORE_REPO_FILES = "STORE_REPO_FILES";
export const UPDATE_REPO_FILE = "UPDATE_REPO_FILE";
export const UPDATE_CODE_CONTENT = "UPDATE_CODE_CONTENT";
export const UPDATE_REPO_FILE_LINK = "UPDATE_REPO_FILE_LINK";

// Load existing diagram from github repo
export const LOAD_DIAGRAM_TO_STORE = "LOAD_DIAGRAM_TO_STORE";
export const LOAD_TEMPLATE_DIAGRAM = "LOAD_TEMPLATE_DIAGRAM";
export const RELOAD_DIAGRAM = "RELOAD_DIAGRAM";

// Display popup notification
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";
export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";

// Updates the z-index of the selected node to control layering
export const UPDATE_NODE_Z_INDEX = "UPDATE_NODE_Z_INDEX";
export const LOADING_NOTIFICATION = "LOADING_NOTIFICATION";

export const LOAD_REPO_FROM_PUBLIC_URL = "LOAD_REPO_FROM_PUBLIC_URL";

export const SET_SOURCE_DOC_TAB = "SET_SOURCE_DOC_TAB";
export const SET_IS_LOADING_DIAGRAM = "SET_IS_LOADING_DIAGRAM";

export const ADD_NODE_TO_SIMULATION = "ADD_NODE_TO_SIMULATION";
export const REMOVE_NODE_FROM_SIMULATION = "REMOVE_NODE_FROM_SIMULATION";
export const ADD_SIMULATION = "ADD_SIMULATION";
export const SET_CURRENT_SIMULATION = "SET_CURRENT_SIMULATION";
